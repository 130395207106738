import { Calendar, Car, Gauge, Star, ChevronRight, Heart } from 'lucide-react'
import { Badge } from '#app/components/ui/badge.tsx'
import { useFetcher } from '@remix-run/react'

import { Separator } from '#app/components/ui/separator.tsx'
import { formatText } from '#app/utils/format-text.tsx'
import { getCarImgSrc } from '#app/utils/misc.tsx'

export function FeaturedCarListing({ data, user, showFavorite = true }: { data: any; user?: any; showFavorite?: boolean }) {
	const favoriteFetcher = useFetcher()

	const isFavorite = user?.favorites?.some((fav: { carId: number }) => fav.carId === data.id)

	const price = data.price.toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})

	//take the state between parenthesis and make it uppercase
	const formatState = (state: string) => {
		const match = state.match(/\(([^)]{2})\)/)
		return match ? match[1].toUpperCase() : ''
	}

	const numberToLocale = (number: number) => {
		return new Intl.NumberFormat('pt-BR').format(number)
	}

	return (
		<div className="group relative flex h-full w-full min-w-full flex-col overflow-hidden rounded-lg border border-border bg-white shadow-md transition-all duration-75 hover:shadow-lg lg:max-w-xs">
			{showFavorite && (
				<favoriteFetcher.Form
					method="post"
					action="/resources/favorite"
				className="absolute right-2 top-2 z-10 rounded-full bg-white/80 p-1 backdrop-blur-sm transition-all hover:bg-white"
			>
				<input type="hidden" name="carId" value={data.id} />
				<input type="hidden" name="carUrl" value={data.url} />
				<input type="hidden" name="userId" value={user?.id} />
				<input
					type="hidden"
					name="intent"
					value={isFavorite ? 'remove' : 'insert'}
				/>
				<button
					onClick={(e) => {
						e.stopPropagation()
					}}
					type="submit"
					className="rounded-full flex items-center justify-center bg-transparent active:scale-75 transition-transform duration-150"
				>
					<Heart
						className={`h-5 w-5 ${
							isFavorite ? 'fill-red-500 text-red-500' : 'text-gray-500'
						}`}
						/>
					</button>
				</favoriteFetcher.Form>
			)} 
			{data.isPaid && (
				<Badge className="absolute left-2 top-2 z-10 rounded-full bg-primary px-2 py-1 text-white shadow-md">
					<Star className="mr-1 inline-block h-3 w-3" />
					<span className="text-xs font-semibold uppercase tracking-wide">
						Destaque
					</span>
				</Badge>
			)}
			<div className="relative flex w-full rounded-t-lg bg-white dark:bg-background">
				{data.images.length === 0 ? (
					<div className={`aspect-[4/3] h-full min-h-[240px] w-full min-w-[320px] flex flex-col items-center justify-center transition-transform duration-300 md:group-hover:scale-105 ${data.isInactive ? 'grayscale' : ''}`}>
						{data.isInactive ? null : (
							<>
								<Car size={60} />
								<span className="text-sm font-semibold tracking-widest text-primary">
									SEM FOTO
								</span>
							</>
						)}
					</div>
				) : (
					<div className="h-full min-h-full w-full min-w-full flex-col items-center justify-center overflow-clip rounded-t-lg bg-muted/15 lg:max-w-xs">
						<img
							loading="lazy"
							alt={`Foto de um ${data.maker} ${data.model} ${data.version}.`}
							src={`https://carregados.com.br/cdn-cgi/image/format=auto,sharpen=1,saturation=1.4,width=960,height=480,fit=contain${getCarImgSrc(
								data.images[0].id,
							)}`}
							className={`aspect-[4/3] h-full min-h-[240px] w-full min-w-[320px] object-cover transition-transform duration-300 md:group-hover:scale-105 ${data.isInactive ? 'grayscale' : ''}`}
						/>
					</div>
				)}
				{data.isInactive ? (
					<div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-40">
						<span className="text-4xl font-bold tracking-widest text-red-600">
							{showFavorite ? 'VENDIDO' : 'INATIVO'}
						</span>
					</div>
				) : null}
			</div>
			<div className="flex w-full flex-col justify-center space-y-2 p-4">
				<div className="flex items-center justify-between">
					<span className="text-sm font-semibold uppercase text-muted-foreground">
						{data.maker}
					</span>
					<span className="text-sm font-semibold capitalize text-muted-foreground">
						{`${data.city} / ${formatState(data.state)}`}
					</span>
				</div>
				<h3 className="line-clamp-1 font-semibold capitalize">{`${formatText(
					data.maker,
				)} ${formatText(data.model)} ${formatText(data.version)}`}</h3>
				<div className="flex justify-between capitalize">
					<div className="flex items-center gap-1">
						<Gauge className="text-muted-foreground" />
						<p className="text-sm text-muted-foreground">{`${numberToLocale(
							data.odometer,
						)} km`}</p>
					</div>
					<div className="flex items-center gap-1">
						<Calendar size={20} className="text-muted-foreground" />
						<p className="text-sm text-muted-foreground">{`${data.fabricationYear}/${data.modelYear}`}</p>
					</div>
					{/* <p className="text-sm text-muted-foreground">{data.condition}</p> */}
				</div>
				<Separator className="mt-8" />
				<div className="flex flex-col">
					<span className="text-xs text-muted-foreground">Preço</span>
					<span className="text-xl font-semibold">{price}</span>
				</div>
			</div>
			<div className="absolute bottom-4 right-4 transition-transform duration-150 group-hover:translate-x-3">
				<ChevronRight size={32} className="text-[#f58b14]" />
			</div>
		</div>
	)
}
